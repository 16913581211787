<template>
  <div class="container dashboard mt-4">
    <div class="row">
      <RangeDateSocial />
      <div class="col-12">
        <social-filter :post="comments" dashboard="true"></social-filter>
      </div>
      <div class="col-12 col-lg-12 mt-3">
        <card type="filter default" >
          <template slot="header">
            <p class="title-1">{{$t('dashboard.itemComentario')}}</p>
          </template>
          <p v-if="redesSociales == null">{{$t('dashboard.comentario.nodata')}}</p>
        </card>
      </div>
      <div class="col-12 col-md-6 mt-4" v-if="loaded">
        <card type="chart">
          <template slot="header">
            <h2 class="card-category">{{$t('dashboard.comentario.grafica1')}}</h2>
            <!--<h3 class="card-title">260</h3>-->
          </template>
          <line-chart
            id="Prueba"
            class="chart-area" style="height: 300px"
            :chartData="redesSociales"
            :extraOptions="extraOptionsLine"
          ></line-chart>
        </card>
      </div>
      <div class="col-12 col-md-6 mt-4" v-if="loaded">
        <card type="chart">
          <template slot="header">
            <h2 class="card-category">{{$t('dashboard.comentario.grafica2')}}</h2>
            <h3 class="card-title">
              <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
            </h3>
          </template>
          <div class="lengens" v-html="htmlLegend"></div>
          <doughnut
            class="chart-area" style="height: 250px;"
            :chartData="chartdatasentimientos"
            :options="extraOptions"
            @generated="setLegend1"
            chart-id="doiun"
          ></doughnut>
        </card>
      </div>
      <div class="col-12 col-md-6 mt-4" v-if="loaded">
        <card type="chart" class="card-hei">
          <template slot="header">
            <h2 class="card-category">{{$t('dashboard.comentario.grafica3')}}</h2>
            <h3 class="card-title">
              <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
            </h3>
          </template>
          <div class="legend-con" v-html="message"></div>
          <doughnut
            class="chart-area" style="height: 250px;"
            :chartData="chartdata"
            :options="extraOptionsFuente"
            @generated="setLegend"
          ></doughnut>
        </card>
      </div>
      <div class="col-12 col-md-6 mt-4" v-if="loaded">
        <card type="chart" id="apenchart">
          <template slot="header">
            <h4 class="mt-3">{{$t('dashboard.comentario.grafica4')}}</h4>
          </template>
          <div >
            <VueApexCharts refs="chart2" class="apexchart" type="bar" height="250" width="400" :options="chartOptionse" :series="series"></VueApexCharts>
          </div>
        </card>
      </div>
      <div class="col-12 col-md-4 mt-4" v-if="loaded">
        <card type="menciones positivas" >
          <template slot="header">
            <h4>{{$t('dashboard.comentario.mencion1')}}</h4>
          </template>
          <div class="content-positivas">
            <ul class="no-padd list" v-if="menciones.positivo">
              <li :class="item.Red_Social" v-for="(item,index) in computedFonksiyon" :key="index">
                <div class="row">
                  <div class="col-3">
                    <img v-if="item.Imagen_perfil" @error="onImgError()"  :src="item.Imagen_perfil && computedPropertyString" alt="prueba vue"/>
                    <img v-else src="../assets/logo.png" alt="prueba vue"/>
                  </div>
                  <div class="col-8">
                    <p><strong>{{ item.Usuario }}</strong></p>
                    <p><span>Menciones {{ item.N_Veces_Compartida }}</span><span class="f-r">Seguidores {{ item.Seguidores }}</span></p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="sinmenciones" v-else>
              <div class="lupa">
                <span></span>
                <div class="center">
                    <div class="wrap">
                        <div class="box-1 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                        <div class="box-2 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                    </div>
                  </div>
              </div>
              <p>{{$t('dashboard.comentario.cardbody')}}</p>
            </div>
          </div>
          <template slot="footer">
            <router-link v-if="menciones.positivo"  :to="$i18nRoute({name: 'Menciones', params: { tipo: 'positivas' }})"  class="nav-link" >{{$t('dashboard.comentario.cardfooter1')}}</router-link>
            <span v-else class="nav-link">{{$t('dashboard.comentario.cardfooter2')}}</span>
          </template>
        </card>
      </div>
      <div class="col-12 col-md-4 mt-4" v-if="loaded">
        <card type="menciones neutrales" >
          <template slot="header">
            <h4>{{$t('dashboard.comentario.mencion2')}}</h4>
          </template>
          <div class="content-positivas">
            <ul class="no-padd list" v-if="menciones.neutral">
              <li :class="item.Red_Social" v-for="(item,index) in computedFonksiyon1" :key="index">
                <div class="row">
                  <div class="col-3">
                    <img v-if="item.Imagen_perfil" @error="onImgError()"  :src="item.Imagen_perfil && computedPropertyString"  alt="prueba vue"/>
                    <img v-else src="../assets/logo.png" alt="prueba vue"/>
                  </div>
                  <div class="col-8">
                    <p><strong>{{ item.Usuario }}</strong></p>
                    <p><span>Menciones {{ item.N_Veces_Compartida }}</span><span class="f-r">Seguidores {{ item.Seguidores }}</span></p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="sinmenciones" v-else>
              <div class="lupa">
                <span></span>
                <div class="center">
                    <div class="wrap">
                        <div class="box-1 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                        <div class="box-2 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                    </div>
                  </div>
              </div>
              <p>{{$t('dashboard.comentario.cardbody')}}</p>
            </div>
          </div>
          <template slot="footer">
            <router-link v-if="menciones.neutral" :to="$i18nRoute({name: 'Menciones', params: { tipo: 'neutrales' }})"  class="nav-link" >{{$t('dashboard.comentario.cardfooter1')}}</router-link>
            <span v-else class="nav-link">{{$t('dashboard.comentario.cardfooter2')}}</span>
          </template>
        </card>
      </div>
      <div class="col-12 col-md-4 mt-4" v-if="loaded">
        <card type="menciones negativas">
          <template slot="header">
            <h4>{{$t('dashboard.comentario.mencion3')}}</h4>
          </template>
          <div class="content-positivas">
            <ul class="no-padd list" v-if="menciones.negativo">
              <li :class="item.Red_Social" v-for="(item,index) in computedFonksiyon2" :key="index">
                <div class="row">
                  <div class="col-3">
                    <img v-if="item.Imagen_perfil " @error="onImgError()"  :src="item.Imagen_perfil && computedPropertyString" alt="prueba vue"/>
                    <img v-else src="../assets/logo.png" alt="prueba vue"/>
                  </div>
                  <div class="col-8">
                    <p><strong>{{ item.Usuario }}</strong></p>
                    <p><span>Menciones {{ item.N_Veces_Compartida }}</span><span class="f-r">Seguidores {{ item.Seguidores }}</span></p>
                  </div>
                </div>
              </li>
            </ul>
            <div class="sinmenciones" v-else>
              <div class="lupa">
                <span></span>
                <div class="center">
                    <div class="wrap">
                        <div class="box-1 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                        <div class="box-2 box">
                            <i class="fas fa-file-code"></i>
                            <i class="fas fa-file-code"></i>
                        </div>
                    </div>
                  </div>
              </div>
              <p>{{$t('dashboard.comentario.cardbody')}}</p>
            </div>
          </div>
          <template slot="footer">
            <router-link v-if="menciones.negativo"  :to="$i18nRoute({name: 'Menciones', params: { tipo: 'negativas' }})"  class="nav-link" >{{$t('dashboard.comentario.cardfooter1')}}</router-link>
            <span v-else class="nav-link">{{$t('dashboard.comentario.cardfooter2')}}</span>
          </template>
        </card>
      </div>
      <div class="col-12 col-lg-12 mt-3" >
        <card type="filter default" >
          <template slot="header">
            <p class="title-1">{{$t('dashboard.itemPublicacion')}}</p>
          </template>
          <p v-if="redesSociales2 == null">{{$t('dashboard.comentario.nodata')}}</p>
        </card>
        <card type="chart grafica mt-3" v-if="redesSociales2 != null">
          <template slot="header">
            <h2 class="card-category">{{$t('dashboard.comentario.grafica1')}}</h2>
            <!--<h3 class="card-title">260</h3>-->
          </template>
          <line-chart
            id="Prueba1"
            class="chart-area" style="height: 300px"
            :chartData="redesSociales2"
            :extraOptions="extraOptionsLine"
          ></line-chart>
        </card>
    </div>
    
    </div>

    
    <modalpeq group="foo1" position="bottom left"  />
  </div>
</template>

<style scoped>
.dashboard {
  padding-left: 50px;
  padding-right: 50px;
}
</style>

<script>
import moment from "moment";
import Card from "@/components/card/Card.component.vue";
import RangeDateSocial from "@/components/rangedatesocial/RangeDateSocial.vue";
import LineChart from "@/components/charts/LineCharts";
import BarChart from "@/components/charts/BarChart";
import Doughnut from "@/components/charts/Doughnut";
import * as chartConfigs from "@/components/charts/config";
import config from "@/components/charts/configlobal";
import wordcloud from "vue-wordcloud";
import SocialFilter from "@/components/redesSociales/social.component"
import VueApexCharts from "vue-apexcharts";
import {mapState, mapMutations } from "vuex";
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'menciones/getField',
  mutationType: 'menciones/updateField',
});
// var objetoRedes = datos;
  var orq = [];
  var reso = [];
  var sentimiento = [];
  var oreq = [];
  var mencionesq = []
  var positivo, neutral, negativo;
  var positivo = [];
  var neutral =[];
  var negativo = [];
  var sieries = []
  var cate = [];
  var datetimes = [];
  var labelsfecha = [];
  var datosline = [];
  var pushlabel = [];
  var fg = [];
  const imgLink = require('../assets/logo.png')
export default {
  name: "Dashoard",
  components: {
    Card,
    RangeDateSocial,
    LineChart,
    BarChart,
    Doughnut,
    wordcloud,
    VueApexCharts,
    SocialFilter,
  },
  data() {
    //TODO: hACER ALGO
    
    return {
      loaded: false,
      comments: null,
      message: 'Hello World',
      htmlLegend: null,
      redesSociales: null,
      redesSociales2: null,
      chartdata: null,
      chartdatasentimientos: null,
      menciones: {},
      extraOptions: chartConfigs.doughnutChartOptions,
      extraOptionsFuente: chartConfigs.doughnutChartOptionsFuentes,
      extraOptionsLine: chartConfigs.greenChartOptions,
      series: [], //{name: "Positivas",data: [0, 30, 20]}, {name: "Neutrales",data: [53, 32, 33]}, {name: "Negativas",data: [12, 17, 11]}],
      chartOptionse: {
        chart: {
          id: 'mychart',
          type: "bar",
          height: 250,
          stacked: true,
          dropShadow: {
            enabled: false,
            blur: 1,
            opacity: 0.25
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "100%",
          },
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          // width: 2,
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: ""
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          shared: true,
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        colors: [],
        fill: {
          //type: "pattern",
          opacity: 1,
          //opacity: 0.9,
          // type: ['gradient', 'gradient'],
          // gradient: {
          //     shade: 'dark',
          //     type: "horizontal",
          //     shadeIntensity: 0.5,
          //     gradientToColors: undefined,
          //     inverseColors: true,
          //     opacityFrom: 1,
          //     opacityTo: 1,
          //     stops: [0, 50, 100],
          //     colorStops: []
          // },
          pattern: {
            style: ["circles", "slantedLines", "verticalLines", "horizontalLines"], // string or array of strings

          }
        },
        states: {
          hover: {
            filter: "none"
          }
        },
        legend: {
          position: "top",
          offsetY: 0
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          }
        },
      },
      prueba12: imgLink
    };
  },
  computed: {
    ...mapFields(['tags']),
    ...mapState("user", ["users"]),
    ...mapState(["social"]),
    computedPropertyString(){
      return (this.imgError) ? "../assets/img/robot.png" : this.prueba12;
      // event.target.src = "../assets/logo.png"
    },
    computedFonksiyon() {
      console.log(this.menciones.positivo);
      if(this.menciones.positivo){
        this.menciones.positivo = this.menciones.positivo.filter(elem => elem.Sentimiento != undefined)
          this.menciones.positivo.sort(function(x, y) {
          return y.N_Seguidores_Amigos- x.N_Seguidores_Amigos;
        });
        return this.menciones.positivo;
      }
      else{
        return console.log("error")
      }
      
    },
    computedFonksiyon1() {
      if(this.menciones.neutral){
        this.menciones.neutral = this.menciones.neutral.filter(elem => elem.Sentimiento != undefined)
          this.menciones.neutral.sort(function(x, y) {
          return y.N_Seguidores_Amigos- x.N_Seguidores_Amigos;
        });
        return this.menciones.neutral;
      }
      else{
        return console.log("no hay information")
      }
    },
    computedFonksiyon2() {
      if(this.menciones.negativo){
        this.menciones.negativo = this.menciones.negativo.filter(elem => elem.Sentimiento != undefined)
        this.menciones.negativo.sort(function(x, y) {
        return y.N_Seguidores_Amigos- x.N_Seguidores_Amigos;
      });
      return this.menciones.negativo;
      }
      else{
        return console.log("error")
      }
    },
    ...mapState("vision", ["dateRange", "fechas"]),
    ...mapState("menciones", ["tags", "tag"]),
    ...mapState(["socialFacebook", "socialInstagram", "socialTwitter"])
  },
  created() { 
    // this.Carga();
    this.dateNow();
    this.getRedes();
  },
  methods: {
    
    // metodo para obtener la fecha del componente rangedate
    ...mapMutations("vision", ["dateNow"]),
    ...mapMutations(["Carga"]),
    onImgError() {
      this.imgError = true;
    },
    setLegend (html) {
      this.message = html
    },
    setLegend1(html){
      this.htmlLegend = html
    },

  //!Sincronizar la cuenta que se debe mostrar
    
    // obtener la busqeuda inicial
    getTag(){
      let prueba = "";
        // let objetotags = JSON.parse(localStorage.getItem("rest"));
        let objetotags = this.tags;
        if(objetotags != null){
          objetotags.forEach(function(x, i) {
            if (i % 2 === 0) {
              if (i == 0) {
                if (i == objetotags.length - 1) {
                  prueba = x.text;
                } else {
                  prueba = x.text + ",";
                }
              } else if (i == objetotags.length - 1) {
                prueba = prueba + "," + x.text;
              } else {
                prueba = prueba + "," + x.text + ",";
              }
            } else {
              prueba = prueba + x.text;
            }
          });
          return prueba;
        }
        else{
          alert("error: no ha registro ninguna busqueda");
          this.Carga();
        }
        
        
    },
    imageUrlAlt(event){
      console.log('prueba');
      // event.target.src = "../assets/logo.png"
    },
    componer(datos, comentarios){
          //Todo: obtener las redes sociales que vengan de la data
          datos.forEach((element,index) =>{
            const redsocial = reso.filter(x => x.RedSocial == element.Red_Social);
            if(redsocial.length == 0){
                reso.push({
                  "RedSocial": element.Red_Social,
              });
            }
          })
          // console.log(reso);
          reso.forEach(x => {
            const redsocial = datos.filter(element => element.Red_Social == x.RedSocial);
            if(redsocial.length != 0){
                orq.push({
                  "resocial": x.RedSocial,
                  "post": redsocial.length
              });
            }
          })
          if(comentarios){
            datos.forEach(x => {
              const ad = sentimiento.filter(user => user.resocial == x.Sentimiento)
                if(ad.length == 0){
                  positivo = datos.filter(user => user.Sentimiento == "Positivo");
                  neutral = datos.filter(user => user.Sentimiento == "Neutral");
                  negativo = datos.filter(user => user.Sentimiento == "Negativo");
                  if(x.Sentimiento == "Positivo"){
                    mencionesq.positivo = positivo
                    sentimiento.push({
                      "resocial": x.Sentimiento,
                      "post": positivo.length
                    })
                  }
                  else if(x.Sentimiento == "Neutral"){
                    mencionesq.neutral = neutral
                    sentimiento.push({
                      "resocial": x.Sentimiento,
                      "post": neutral.length
                    })
                  }
                  else{
                    mencionesq.negativo = negativo
                    sentimiento.push({
                      "resocial": x.Sentimiento,
                      "post": negativo.length
                    })
                  }

                }
            })
            //Todo: obtener los datos para apexchart bar horizontal solo la clasificación y dato
            sentimiento.forEach((we,ix) =>{
              orq.forEach((x,i) => {
                const ady = datos.filter(otro => otro.Sentimiento == we.resocial && otro.Red_Social == x.resocial)
                if(we.resocial == "Positivo"){positivo.push(ady.length)}
                if(we.resocial == "Neutral"){neutral.push(ady.length)}
                if(we.resocial == "Negativo"){negativo.push(ady.length)}
                const ser = sieries.filter(otro => otro.name == we.resocial)
                if(ser == 0){
                  sieries.push({
                    name: we.resocial,
                    data: []
                  })
                }
              })
            })
            sentimiento.forEach((item,index)=> {
              const resultado = sieries.filter(otro => otro.name == item.resocial)
              // var mencion = data.filter(x => x.Sentimiento == item.resocial )
              item.resocial == "Positivo" ? resultado[0].data = positivo : console.log("")
              item.resocial == "Neutral" ? resultado[0].data = neutral : console.log("")
              item.resocial == "Negativo" ? resultado[0].data = negativo : console.log("")
            })
           
          //  console.log(sentimiento, reso, orq);
          
          //Todo: setear las redes sociales que contriubyen al apex char bar horizontal 
          // this.chartOptionse.xaxis.categories = [];
            orq.forEach((item) => {
              cate.push(item.resocial)
            })
            this.chartOptionse = {
              ...this.chartOptionse,
              ...{
                xaxis: {
                  categories: cate
                }
              }
            };
          }
          

          

          
          // if(oldVal){
          //   debugger;
            
          // }
          // else{
          //   debugger;
          //   orq.forEach((item) => {
          //     this.chartOptionse.xaxis.categories.push(item.resocial.substring(1))
          //   })
          // }

          //Todo: obtener las fechas para el line chart que tienen data
          datos.forEach((x,i) => {
            let date = moment(x.Fecha, "MMMM DD YYYY").format("MM/DD");
            if(date == "Invalid date"){
             date = moment(new Date(x.Fecha), "MMMM DD YYYY").format("MM/DD");
            }
            datos[i].fecha2 = date;
            let hoy = new Date(this.dateRange.endDate);
            let another = new Date(this.dateRange.startDate);
            var fecha1 = moment(this.dateRange.endDate);
            var fecha2 = moment(this.dateRange.startDate);
            if(i <= fecha1.diff(fecha2, 'days')){
              hoy = hoy.setDate(hoy.getDate() - i)
              hoy = new Date(hoy)
              hoy = moment(hoy, "YYYYMMDD").format("MM/DD");
              let fecha = datetimes.filter(otro => otro == date )
              let labels = labelsfecha.filter(otro => otro == hoy )
              if(fecha.length == 0){
                if( date != "Invalid date"){
                  datetimes.push(date);
                }
              }
              if(labels.length == 0){
                labelsfecha.push(hoy);
              }
            }
            else{
              let fecha = datetimes.filter(otro => otro == date )
              if(fecha.length == 0){
                if( date != "Invalid date"){
                  datetimes.push(date);
                }
              }
            }
          })

          //!Obtener los días de acuerdo al rango de fechas indicados
          let hoyrange = new Date(this.dateRange.endDate);
          let anotherrange = new Date(this.dateRange.startDate);
          var fecha1range = moment(this.dateRange.endDate);
          var fecha2range = moment(this.dateRange.startDate);
          if(labelsfecha.length != fecha1range.diff(fecha2range, 'days') + 1){
            for(let i= 0; i <= fecha1range.diff(fecha2range, 'days'); i++ ){
              let hoyrange1 = new Date(this.dateRange.endDate);
              let anotherrange1 = new Date(this.dateRange.startDate);
              var fecha1range1 = moment(this.dateRange.endDate);
              var fecha2range1 = moment(this.dateRange.startDate);
              hoyrange1 = hoyrange1.setDate(hoyrange1.getDate() - i)
              hoyrange1 = new Date(hoyrange1)
              hoyrange1 = moment(hoyrange1, "YYYYMMDD").format("MM/DD");
              let labels = labelsfecha.filter(otro => otro == hoyrange1 )
              if(labels.length == 0){
                  labelsfecha.push(hoyrange1);
              }
            }
          }
          //! centralizar la información es un solo objeto de line chart que contiene
          //! redsocial, fecha y dato
          //! console.log(labelsfecha);
          
          labelsfecha.forEach(x => {
            orq.forEach(item => {
              const getResul = datos.filter(otro => otro.fecha2 == x && otro.Red_Social == item.resocial)
              if(getResul != 0){
                datosline.push({
                  RedSocial: item.resocial,
                  Fecha: x,
                  dato: getResul.length
                });
              }
            })
          })
          // console.log(datosline);
          labelsfecha.forEach(x => {
            pushlabel.push({
                  label: x
              });
          })
          
          //! supuestamente ya vemos
            orq.forEach(xitem => {
              labelsfecha.forEach(item => {
                const dat = datosline.filter(otro => otro.RedSocial == xitem.resocial && otro.Fecha == item)
                if(dat.length != 0){
                  const dats = fg.filter(otro => otro.label == xitem.resocial)
                  if(dats.length != 0){
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat[0].dato)
                  }
                  else{
                    fg.push({
                      label:xitem.resocial,
                      data: [],
                      fill: true,
                      borderColor: config.colors.primary,
                      borderWidth: 2,
                      borderDash: [],
                      backgroundColor:"transparent",
                      borderDashOffset: 0.0,
                      pointBackgroundColor: config.colors.primary,
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: config.colors.info,
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 4,
                      tension: 0
                    })
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat[0].dato)
                  }
                }
                else{
                  const dats1 = fg.filter(otro => otro.label == xitem.resocial)
                  if(dats1.length != 0){
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat.length)
                  }
                  else{
                    fg.push({
                      label:xitem.resocial,
                      data: [],
                      fill: true,
                      borderColor: config.colors.primary,
                      borderWidth: 2,
                      borderDash: [],
                      backgroundColor:"transparent",
                      borderDashOffset: 0.0,
                      pointBackgroundColor: config.colors.primary,
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: config.colors.info,
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 4,
                    })
                    let indextmp = "";
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat.length)
                  }
                }

              })
            })
          fg.forEach((element, index) => {
            if(pushlabel[index] != undefined){
              pushlabel[index].total = element;
            }
            else{
              pushlabel[index] = {};
              pushlabel[index].total = element;
            }
            
          })
          if(comentarios){
            this.chartOptionse.colors = [];
            sieries.forEach(item => {
              if(item.name == "Negativo"){
                this.chartOptionse.colors.push("#E44E48")
              }
              else if(item.name == "Positivo"){
                this.chartOptionse.colors.push("#5BC542")
              }
              else{
                this.chartOptionse.colors.push("#EAEAEA")
              }
            })
          }
          
    },
    //! obtener la información de todos los componentes
    async getRedes(){
      try {
        this.Carga();
        const tag = this.getTag();
        let datos = null;
        const id = this.social.filter(item => item.view == true)
        //! Validación para saber si es una actualización o esta cargando la página
        if(!this.fechas[0].result){
          const { data } = await this.axios.get(`/bextsocial/search/${id[0].Id_Empresa}/${tag}`);
          datos =  data;
        }
        else{
          var redSq = [];
          if(this.socialFacebook){
            redSq.push("Facebook");
          }
          if(this.socialInstagram){
            redSq.push("Instagram");
          }
          if(this.socialTwitter){
            redSq.push("Twitter");
          }
          var prueba = this.fechas[0].start;
          var prueba1 = this.fechas[0].end;
            const { data } = await this.axios.get(`/bextsocial/date/${id[0].Id_Empresa}/${tag}/${prueba},${prueba1}/${redSq}`);
            datos =  data;
            
        }
        // console.log(datos);
        //! Validación para saber si existen datos relaciones que obtenemos de vuex modulo vision
        if(datos == "No se encontro data relacionada a esas fechas y tags"){
          this.loaded = false;
            if(tag != ""){
              this.Carga();
              this.$hijonew({
                  group: 'foo1',
                  type: 'warning',
                  title: this.$t('dashboard.titlemodalad'),
                  text: this.$t('dashboard.error'),
                  duration: 1000,
                  speed: 1000
              })
            }
        } else{
          // datos = datos.shift()
          //Todo:  Obtener datos para saber cuales son las redes sociales existenten y la cantidad de menciones por cada clasificacion

          this.comments = datos
          this.redesSociales = null;
          let comentarios = true;
          console.log(datos);
          
          this.componer(datos, comentarios)


          // this.Carga();
          // pushlabel.forEach(item =>{ 
          //   if(item.total){
          //     debugger;
          //     item.total["label"] = item.total["label"].substring(1);
          //   }
          //   })
          //   debugger
          // orq.forEach(item => item.resocial = item.resocial.substring(1));
          // console.log(orq);
          this.redesSociales = pushlabel;
          this.chartdata = orq;
          this.chartdatasentimientos = sentimiento;
          this.menciones = mencionesq;
          this.series = sieries;
          this.loaded = true
          this.clearArrays();
          
          let publishing = await this.axios.get(`/bextsocial/publication/${id[0].Id_Empresa}/${tag}/${prueba},${prueba1}/${redSq}`)

          if(publishing.data == "No se encontro data relacionada a esas fechas y tags"){
            this.Carga();
            this.$hijonew({
              group: 'foo1',
              type: 'warning',
              title: this.$t('dashboard.titlemodalad'),
              text: this.$t('dashboard.advertencia'),
              duration: 1000,
              speed: 1000
            })
          }
          else{
            publishing = publishing.data;
            this.componer(publishing)
            
            //!get sentimientos de facebook post
            const facebookPost = publishing.filter(item => item.Red_Social == 'Facebook');
            facebookPost.forEach((element,index) => {
              if(element.Fbk_Reacciones < 1){
                facebookPost[index].Sentimiento = "Negativo"
              }
              else{
                let goodfeelds = parseInt(element.Fbk_ReaHAHA, 10 )+ parseInt(element.Fbk_ReaLOVE, 10 )  + parseInt(element.Fbk_ReaLikes, 10 ) + parseInt(element.Fbk_ReaWOW, 10 );
                let badfeelds = parseInt(element.Fbk_ReaANGRY, 10 ) + parseInt(element.Fbk_ReaSAD, 10 );
                let feelds = goodfeelds - badfeelds > 0 ? (goodfeelds - badfeelds) * 100 / parseInt(element.Fbk_Reacciones, 10 ) : feelds = 0;
                if(feelds < 53 && feelds > 47){
                  facebookPost[index].Sentimiento = "Neutral"
                }
                else if(feelds > 53) {
                  facebookPost[index].Sentimiento = "Positivo"
                }
                else{
                  facebookPost[index].Sentimiento = "Negativo"
                }
              }
            })
            //!get sentimientos de facebook post
            const instagramPost = publishing.filter(item => item.Red_Social == 'Instagram');
            instagramPost.forEach((element,index) => {
              if(element.Fbk_Reacciones < 1){
                instagramPost[index].Sentimiento = "Negativo"
              }
              else{
                let feelds = parseInt(element.Intg_Media_Like_count, 10 ) > 0 ? parseInt(element.Intg_Media_Like_count, 10 ) * 100 / parseInt(element.Intg_Media_Like_count, 10 ) : feelds = 0;
                if(feelds < 53 && feelds > 47){
                  instagramPost[index].Sentimiento = "Neutral"
                }
                else if(feelds > 53) {
                  instagramPost[index].Sentimiento = "Positivo"
                }
                else{
                  instagramPost[index].Sentimiento = "Negativo"
                }
              }
            })
            // console.log(instagramPost);
            this.Carga();
            this.redesSociales2= pushlabel
            this.clearArrays();
          }
          

        }
      } catch (error) {
        this.Carga()
        console.log(error)
        this.$hijonew({
          group: 'foo1',
          type: 'warning',
          title: this.$t('dashboard.titlemodalad'),
          text: this.$t('dashboard.descerrormodal'),
          duration: 1000,
          speed: 1000
        })
      }
    },
    clearArrays(){
      orq = [];
      reso = [];
      sentimiento = [];
      oreq = [];
      mencionesq = []
      positivo = null, neutral = null, negativo = null;
      positivo = [];
      neutral =[];
      negativo = [];
      sieries = []
      cate = [];
      datetimes = [];
      labelsfecha = [];
      datosline = [];
      pushlabel = [];
      fg = [];
    }
  },
  mounted() {
    
  },
  watch: {
    // '$store.state.vision.dateRange': function() {
    //   console.log(this.$store.statdateRangee.vision.dateRange, "hola care")
    // }
    dateRange: function () {
          this.dateNow();
          this.getRedes()
    },
    tags: function(){
        this.getRedes()
    },
    socialFacebook(){
      this.getRedes()
    },
    socialInstagram(){
      this.getRedes()
    },
    socialTwitter(){
      this.getRedes()
    }
  }
};
</script>